@import "../../assets/scss/variables.scss";

.fileInputContainer {
  width: 170px;
  height: 140px;
  background-color: $lightTeal;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.roundImgContainer {
  width: 177px;
  height: 177px;
  clip-path: circle();
  background-color: $lightTeal;
}

.upload {
  width: max-content;
  position: relative;
  margin: auto;
}

.upload img {
  border-radius: 50%;
}

.upload .round {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  cursor: pointer;
}

.upload .round input[type="file"] {
  position: absolute;
  transform: scale(2);
}

.customFileInputContainer {
  width: fit-content;
  border: 1px solid $orange;
  border-radius: 6px;
  height: 47px;
  font-weight: 600;
  font-size: 17px;
  padding: 7px 25px;
  display: flex;
  align-items: center;
  color: $orange;
  cursor: pointer;
}

.customFileInputContainer:hover {
  background-color: rgba(253, 110, 63, 0.1);
}
