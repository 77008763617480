@import "../../../assets/scss/variables.scss";

.progressBarContainer {
  min-height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.guideName {
  color: black !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  margin-bottom: 20px !important;
  margin-top: 35px !important;
}