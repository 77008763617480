@import "../../assets/scss/variables.scss";

.promotionClassContainer {
  padding: 25px !important;
  width: 950px !important;

  .confirmContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .confirmHeader {
    font-weight: bold !important;
    font-size: 20px;
    color: #000;
  }

  .subText {
    font-weight: 400;
    font-size: 15px;
    color: $wood;
    padding-top: 10px;
  }

  .closeIcon {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .closeIcon:hover {
    opacity: 0.8;
  }
}

.confirmationModelContainer {
  padding: 25px !important;
  max-width: 450px !important;
  min-height: max-content !important;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .title {
    font-weight: bold !important;
    font-size: 20px;
    color: black;
  }

  .message {
    font-weight: 400;
    font-size: 15px;
    color: $wood;
    padding-top: 10px;
  }

  .closeIcon {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .closeIcon:hover {
    opacity: 0.8;
  }
}

.bannerSubText {
  font-weight: 400;
  font-size: 14px;
  color: black;
  padding-top: 10px;
}