@import "../../assets/scss/variables.scss";

.switchToDataSummery {
  text-decoration: underline;
  color: $orange;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer;
}

.divider {
  margin-bottom: 15px !important;
  margin-top: 3px !important;
}

.topbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.yearText {
  font-weight: 600 !important;
  color: $darkGrey;
}

.marginLeft {
  margin-left: 25px !important;
}

.containerForProgressbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.classesAndPercentContainer {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}
