@import "../../assets/scss/variables.scss";

.button {
  border-radius: 6px !important;
  height: 47px !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  padding: 7px 25px !important;
}
.button:hover {
  border-color: $orange !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  padding: 7px 25px !important;
}

.twoButtonStack {
  margin-top: 30px;
}
