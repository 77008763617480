@import "../../assets/scss/variables.scss";

.tagContainer {
  background-color: $lightBlue !important;
  border-radius: 8px !important;
  padding: 12px !important;
  width: fit-content !important;
  display: flex !important;
  border: 1px solid #40AFA8 !important;
}

.tagContainer:hover {
  background-color: #e4fffe !important;
  cursor: pointer !important;
  border: 1px solid #0D3334 !important;
}

.font {
  color: $wood !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.crossIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.emailTagsContainer {
  display: flex !important;
  flex-direction: row !important;
  background-color: $lightBlue !important;
  border-radius: 8px !important;
  padding: 12px !important;
  width: fit-content !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: copy !important;
  gap: 15px !important;
  align-items: center;
  justify-content: center;
}

.emailTextContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 7px !important;
}

.copyIconStyle {
  width: 25px;
  height: 25px;
}

.emailTagTxt1 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.emailTagTxt2 {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: gray !important;
}

.copiedText {
  color: $green !important;
  font-size: 13px !important;
}