@import "../../assets/scss/variables.scss";

.closeIcon {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.subTextEmailTemplate {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: $wood !important;
  margin-top: 22px !important;
}

.subject {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: $wood !important;
  margin-top: 35px !important;
}

.templateBody {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: $wood !important;
  margin-top: 55px !important;
}

.previewEmailTitle {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: black !important;
}

.selectCityHeader {
  font-weight: 600 !important;
  font-size: 22px !important;
  color: black !important;
}

.cityNameStyle {
  font-weight: 500 !important;
  font-size: 17px !important;
  color: $wood !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  cursor: pointer;
  width: fit-content !important;
}

.selectedCityName {
  font-weight: 600 !important;
  font-size: 17px !important;
  color: black !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  cursor: pointer;
  width: fit-content !important;
}

.cityDivider {
  margin: 2px !important;
}

.cityHeaderImgContainer {
  top: 0px;
  padding: 30px;
  padding-bottom: 0px;
  padding-top: 30px;
  position: sticky;
  background-color: white;
  z-index: 100000;
}

.citySelectBottomContainer {
  position: sticky;
  bottom: 0;
  min-height: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px -6px 10px -3px #e4eaf6;
  gap: 20px;
  padding: 15px;
}

.applyCityButton {
  width: 60% !important;
  padding: 10px !important;
  color: white !important;
  background-color: $orange !important;
}

.citiesCountStyle {
  font-weight: 400 !important;
  font-size: 15px !important;
}

.selectCitySubHeading {
  font-weight: 400 !important;
  font-size: 15px !important;
}

.headingStyle {
  display: flex;
  flex-direction: column;
  gap: 15px;
}