body {
  margin: 0px;
  padding: 0px;
  background: #fff;
  font-family: Poppins, Arial, Helvetica, Verdana, Tahoma, sans-serif;
  font-style: normal;
  overflow-y: hidden;
}

.ag-theme-alpine {
  --ag-header-foreground-color: white;
  --ag-header-background-color: #CDA6FF;
}
