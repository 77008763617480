@import "../../../assets/scss/variables.scss";

.noteText {
  margin-top: 20px !important;
  font-size: 13px !important;
  color: $dustkyBrown !important;
}

.templateBodyText {
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  color: $wood !important;
}

.textEditorContainer {
  border: 1px solid #c3c3c3;
  padding: 10px;
  border-radius: 6px;
}

.progressBarContainer {
  min-height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.instructionsContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 15px;
  font-size: 13px;
}
