@import "../../../assets/scss/variables.scss";

.editButton {
  width: 250px !important;
  margin-right: 10px !important;
}

.backbutton {
  width: 210px !important;
}

.tableLabel {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: black !important;
  margin-bottom: 20px !important;
}

.otherImageContainer {
  width: 100%;
  height: 280px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.locationImageContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.mediumFontHeader {
  font-weight: 600 !important;
  font-size: 22px !important;
  margin-bottom: 15px !important;
  color: black !important;
  width: fit-content !important;
}

.locationHeader {
  font-weight: 600 !important;
  font-size: 22px !important;
  color: black !important;
}

.label {
  width: max-content !important;
  color: $lightGrey !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  margin-bottom: 15px !important;
}

.progressBarContainer {
  min-height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.coverImageContainer {
  width: 100%;
  height: 270px;
}

.imageUploadContainer {
  width: 390px;
  height: 295px;
}
