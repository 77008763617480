@import "../../../assets/scss/variables.scss";

.bottomBoxContainer {
  position: absolute;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 30px;
  bottom: 0px;
  width: calc(100% - 380px) !important;
  background-color: white;

  .bottomTextAddadmin {
    font-weight: 400;
    font-size: 12px;
    color: $wood;
    font-style: italic;
  }
  .bottomTextEdit {
    font-weight: 400;
    font-size: 18px;
    color: $orange;
  }
}

.loaderContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
