@import "../../assets/scss/variables.scss";

.cancelClassHeaderContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerText {
  font-weight: 600 !important;
  color: black !important;
  font-size: 20px !important;
}

.reasonToCancelText {
  font-weight: 400 !important;
  font-size: 15px !important;
  width: 100% !important;
}

.closeIcon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
