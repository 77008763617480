@import "../../assets/scss/variables.scss";

.filterCountryDateHeader {
  font-weight: 500;
  font-size: 1.25em;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.filterHeader {
  font-weight: 600;
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonsWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.buttonApply {
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: $orange !important;
  color: $white !important;
  border: none !important;
}

.buttonCancel {
  padding-left: 40px !important;
  padding-right: 40px !important;
  color: $orange !important;
  border-color: $orange !important;
}

.buttonFont {
  font-weight: 600 !important;
  font-size: 1.125em !important;
}

.clearFilters {
  font-weight: 500;
  font-size: 0.875em;
  margin: 0px;
  color: $orange;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}
