@import "../../assets/scss/variables.scss";

.promoCardContainer {
  width: max-content;
  display: flex;
  flex-direction: row;
  background-color: $lightBlue;
  padding: 10px;
  padding-right: 40px;
  cursor: move;
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  .fontStyle {
    color: $wood;
    font-weight: 400;
    font-size: 12px;
  }

  .classNameStyle {
    font-weight: 600;
    font-size: 14px;
  }
}

.promoBannerImage {
  float: left;
  width: 100px;
  height: 100%;
}