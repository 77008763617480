@import "../../assets/scss/variables.scss";

.images {
  border-radius: 8px;
  //filter: drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.15))
}

.imageContainer {
  width: 400px;
  height: 300px;
  gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mediumFontHeader {
  font-weight: 600 !important;
  font-size: 20px !important;
  margin-bottom: 15px !important;
  color: black !important;
}

.deleteIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.missingImgContainer {
  width: 100%;
  height: 270px;
  background-color: $lightTeal;
  border-radius: 8px;
}

.roundImgContainer {
  width: 175px;
  height: 175px;
  clip-path: circle();
  background-color: $lightTeal;
}
