@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
$white: #ffffff;
$darkGrey: #0d3334;
$lightGreen: #b2df64;
$green: #4ec032;
$wood: #353535;
$lightBlue: #f0f7f8;
$dustkyBrown: #a29191;
$lightTeal: #e7e1e1;
$teal: #40afa8;
$orange: #ffb985;
$lightGrey: #A19191;
$largerFont: 24px; //2em;
$largeFont: 24px; //1.5em;
$mediateFont: 18px; //1.125em;
$mediumFont: 16px; // 1em;
$smallFont: 14px; //0.875em;
$smallerFont: 12px; //0.75em;
$extraSmallFont: 11px; // 0.6875em;
$whiteBackground: rgba(255, 255, 255, 0.7);
$PrimaryFont: Poppins, "Open Sans", sans-serif;

a {
  color: $orange;
  cursor: pointer;
  text-decoration: none;
  font-size: $smallFont;
  &:hover {
    color: $green;
  }
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

img,
svg {
  vertical-align: middle;
}

*,
::after,
::before {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $wood;
}
