.heading {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #a29191 !important;
  margin-bottom: 5px !important;
}

.subheading {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 40px !important;
  color: #353535 !important;
}

.devider {
  width: 1019px;
  height: 0px;
  border: 0.5px solid #a29191;
  flex: none;
  order: 5;
  flex-grow: 0;
}

.button {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.button:hover {
  font-weight: 600 !important;
  font-size: 17px !important;
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.citiesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  word-break: break-all;
}

.countryNameStyle {
  font-weight: 500 !important;
  font-size: 17px !important;
  margin-bottom: 15px !important;
  margin-top: 20px !important;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.classCateoryText {
  font-weight: 400 !important;
  font-size: 15px !important;
  margin-top: 5px !important;
}

.loaderContainer {
  width: 100% !important;
  height: 90% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}
