@import "../../assets/scss/variables.scss";

.label {
  width: max-content !important;
  color: $lightGrey;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.value {
  width: fit-content !important;
  color: $wood;
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-top: 10px !important;
  white-space: pre-wrap !important;
}
