@import "../../assets/scss/variables.scss";

.textDeleteContainer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 30px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.textStyle {
  font-style: normal !important;
  font-weight: 500 !important;
  color: $wood !important;
  font-size: 15px !important;
}

.deleteIcon {
  cursor: pointer;
  width: 27px;
  height: 27px;
}

.dotAndTextStyle {
  display: flex;
  flex-direction: row;
  gap: "10px";
}
