@import "../assets/scss/variables.scss";

.countListHolderContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 5px 0 25px;
}

.editButton {
  width: 250px !important;
  margin-right: 10px !important;
}

.backbutton {
  width: 210px !important;
}

.otherImageContainer {
  width: fit-content;
  height: 280px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.mediumFontHeader {
  font-weight: 600 !important;
  font-size: 22px !important;
  margin-bottom: 15px !important;
  color: black !important;
}

.label {
  width: max-content !important;
  color: $lightGrey !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  margin-bottom: 15px !important;
}

.progressBarContainer {
  min-height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.errorPageContainer {
  min-height: 90% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.name {
  color: black !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  margin-bottom: 20px !important;
  margin-top: 35px !important;
}
