@import "../../assets/scss/variables.scss";

.locationHeader {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.deleteIconStyle {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.targetedCityContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.citiesListingContainer {
  width: 100%;
  min-height: 55px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
}

.citiesListAddContainer {
  width: 100%;
  min-height: 55px;
  max-height: 400px;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #c3c3c3;
  flex-wrap: wrap;
  overflow-y: auto;
  border-radius: 6px;
}

.selectedAllCityMsg {
  font-weight: 500 !important;
  font-size: 15px !important;
}

.citiesEmptyErrorText {
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #f73030 !important;
}
