@import "../../../assets/scss/variables.scss";

.currencyStyle {
  font-size: 15px;
  font-weight: 500;
  color: $wood;
}

.progressBarContainer {
  min-height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.label {
  width: max-content !important;
  color: $lightGrey;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.value {
  width: fit-content !important;
  color: $wood;
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-top: 7px !important;
  word-break: break-all;
}

.editButton {
  width: 220px !important;
  margin-right: 10px !important;
}

.backbutton {
  width: 210px !important;
}

.dotAndTextStyle {
  display: flex;
  flex-direction: row;
  gap: "10px";
}

.textDeleteBoxStyle {
  cursor: grab !important;
}
