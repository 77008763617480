@import "../../assets/scss/variables.scss";

.deleteContainer {
  padding: 25px !important;
  max-width: 450px !important;
  min-height: max-content !important;

  .confirmContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .confirmHeader {
    font-weight: bold !important;
    font-size: 20px;
    color: black;
  }

  .subText {
    font-weight: 400;
    font-size: 15px;
    color: $wood;
    padding-top: 10px;
  }

  .closeIcon {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .closeIcon:hover {
    opacity: 0.8;
  }
}