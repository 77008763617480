@import "../../assets/scss/variables.scss";

.cropperHeader {
  font-size: 25px !important;
  font-weight: 600 !important;
}

.cropperHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.closeIconStyle {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
}

.cropButtonStyle {
  margin-top: 0px !important;
  border-radius: 6px !important;
}

.progressbarContainer {
  background-color: $lightTeal;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
